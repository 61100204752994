//
// Gatsby Tinongo template for /sportarten/{slug} pages
//

import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import AppShell from '../components/app-shell';
import Content from '../components/content';
import Section from '../components/section';
import SEO from '../components/seo';

import styles from './sportarten-pages.module.scss';

/* __<ListItem> component__ */
function ListItem(props) {
  return (
    <li className={styles.linksListItem}>
      <Link className={styles.link} to={`/sportart/${props.value.slug}`}>
        <div className={styles.linkThumb}>
          <Img
            key={props.value.image.id}
            fixed={props.value.image.fixed}
            alt={`Bild ${props.value.titel}`}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </div>
        <div>
          <h2 className={styles.linkTitle}>{props.value.titel}</h2>
          <span className={styles.linkAge}>
            Geeignet ab: {props.value.eintrittsalter}
          </span>
        </div>
      </Link>
    </li>
  );
}

/* <Sportarten> component */
export default function Sports({ pageContext }) {
  const sportsLinks = pageContext.sports.map((item) => (
    <ListItem key={item.id} value={item} />
  ));

  const header = (
    <header>
      <h1 className="pageTitle">{pageContext.title}</h1>
    </header>
  );

  const sports = (
    <div>
      <Section fullWidth>
        <ul className={styles.linksList}>{sportsLinks}</ul>
      </Section>
    </div>
  );

  return (
    <>
      <SEO title={pageContext.title} description={pageContext.description} />
      <AppShell>
        <Content>
          <article>
            {header}
            {pageContext.sports.length > 0 && sports}
          </article>
        </Content>
      </AppShell>
    </>
  );
}
